@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-4 py-2 text-sm font-bold uppercase rounded-md;
  }
  .btn-xs {
    @apply px-2 py-1 text-xs font-bold rounded-md;
  }
  .btn-primary {
    @apply btn bg-orange text-white hover:bg-darkorange focus:outline-none disabled:bg-lightgray;
  }
  .btn-primary-xs {
    @apply btn-xs bg-orange text-white hover:bg-darkorange focus:outline-none disabled:bg-lightgray;
  }
  .btn-primary-outlined {
    @apply btn border-orange text-orange border hover:bg-orange hover:bg-opacity-5 focus:outline-none;
  }
  .btn-primary-outlined-xs {
    @apply btn-xs border-orange text-orange border hover:bg-orange hover:bg-opacity-5 focus:outline-none;
  }
  .btn-secondary {
    @apply btn bg-teal text-white hover:bg-alpineTeal disabled:bg-lightgray;
  }
  .btn-danger-outlined {
    @apply btn border-red text-red border hover:bg-orange hover:bg-opacity-5 focus:outline-none;
  }
  .btn-secondary-xs {
    @apply btn-xs bg-teal text-white hover:bg-alpineTeal;
  }
  .btn-secondary-outlined {
    @apply btn border-alpineTeal text-alpineTeal border hover:bg-alpineTeal hover:bg-opacity-5;
  }
}

/* gondola-blog is a class we use to control styles on the Medium-provided blog post html */
.gondola-blog p {
  padding-top: 10px;
  padding-bottom: 12px;
}

.gondola-blog figure img {
  margin-right: auto;
  display: block;
  max-height: 400px;
  margin-left: auto;
}

.gondola-blog figure {
  padding-top: 16px;
  margin-bottom: 16px;
}
.gondola-blog blockquote {
  font-style: italic;
  border-left: 3px solid black;
  padding-left: 12px;
  margin: 12px 0;
}
.gondola-blog figcaption {
  text-align: center;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 10px;
}

.gondola-blog p a {
  text-decoration: underline;
}

@keyframes opacityText {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.text-fadein-3s {
  animation: opacityText 3s forwards;
}

.text-fadein-5s {
  animation: opacityText 5s forwards;
}

.text-fadein-7s {
  animation: opacityText 7s forwards;
}

.text-fadein-10s {
  animation: opacityText 10s forwards;
}

@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.opacity-fade-in {
  animation: opacityFadeIn 3s forwards;
}

.fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.fade-exit-active {
  opacity: 0;
}

@keyframes fadeInMoveUp {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-fadeInMoveUp {
  animation: fadeInMoveUp 1s 1;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

.marquee-content-fast {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes dropDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-dropDown {
  animation: dropDown 1s 1;
};
